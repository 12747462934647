"use client"

import processoutLogoWithText from "app/auth/_assets/images/processoutLogoWithText.svg?url"
import clsx from "clsx"
import Image from "next/image"

type ProcessOutLogoProps = {
  className?: string
}

export default function ProcessOutLogo({ className }: ProcessOutLogoProps) {
  return (
    <div className={clsx("h-10 w-40", className)}>
      <Image
        src={processoutLogoWithText}
        alt="Processout Logo"
        fill
        className="object-contain"
        unoptimized
      />
    </div>
  )
}
