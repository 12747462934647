"use client"

import orchestration from "app/auth/_assets/images/orchestration.gif"
import Image from "next/image"

type ProcessOutLogoProps = {
  className?: string
}

export default function Orchestration({ className }: ProcessOutLogoProps) {
  return (
    <div className={className}>
      <Image
        src={orchestration}
        alt="Orchestration"
        width={981}
        height={748}
        priority
        unoptimized
      />
    </div>
  )
}
